<template>
  <div class="mt-4 flex flex-col">
    <div class="flex justify-end" v-if="with_search">
      <div class="md:w-1/4">
        <div class="mt-1 flex rounded-md shadow-sm">
          <div class="relative flex items-stretch flex-grow focus-within:z-10">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <i class="fas fa-search text-gray-400"></i>
            </div>
            <input v-model="filter" type="text" name="search" id="search" class="focus:ring-dwgreen-500 focus:border-dwgreen-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" placeholder="Zoeken...">
          </div>
          <button @click="clearFilter" type="button" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-dwgreen-500 focus:border-dwgreen-500">
            <i class="fas fa-trash text-gray-400"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-4">
        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:hidden">Data</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:hidden"><span class="sr-only">Bekijken</span></th>
                <th :class="{'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hidden lg:table-cell': header['key'] != 'link', 'relative py-3.5 pl-3 pr-4 sm:pr-6' : header['key'] == 'link'}" v-for="(header, key) in headers" :key="key" scope="col">
                  <div v-if="header['key'] != 'link'" @click="setSort(header)" class="group inline-flex">
                    {{header["name"]}}
                    <span class="ml-2 flex-none rounded" :class="{'bg-gray-200 text-gray-900 group-hover:bg-gray-300': isActive(header), 'invisible text-gray-400 group-hover:visible group-focus:visible': !isActive(header)}">
                      <svg v-if="order == 'asc'" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" linkBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                      <svg v-if="order == 'desc'" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" linkBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </div>
                  <span v-if="header['key'] == 'link'" class="sr-only">Bekijken</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="(row, key) in sorted_data" :key="key">
                <td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto lg:hidden sm:max-w-none sm:pl-6">
                  <!-- {{row[1]["value"]}} -->
                  <img v-if="row[1]['key'] == 'image' && row[1]['value'] != false" :src="row[1]['value']" class="h-8 w-8 rounded-full"/>
                  <svg v-else fill="currentColor" viewBox="0 0 24 24" class="h-8 w-8 rounded-full text-gray-300 bg-gray-100"><path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
                  <dl class="font-normal lg:hidden">
                    <dd class="mt-1 truncate text-gray-700" v-if="mobile_column['key'] != 'link'" v-for="(mobile_column, mobile_data_key) in mobileRow(row)" :key="mobile_data_key">{{mobile_column["value"]}}</dd>
                  </dl>
                </td>
                <td :class="{'relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6': column['key'] == 'link', ' py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 hidden lg:table-cell': column['key'] != 'link'}" v-for="(column, data_key) in row" :key="data_key">
                  <span v-if="column['key'] != 'link' && column['key'] != 'badge' && column['key'] != 'image'">{{ column["value"] }}</span>
                  <img v-if="column['key'] == 'image' && column['value'] != false" :src="column['value']" class="h-8 w-8 rounded-full"/>
                  <svg v-if="column['key'] == 'image' && column['value'] == false" fill="currentColor" viewBox="0 0 24 24" class="h-8 w-8 rounded-full text-gray-300 bg-gray-100"><path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
                  <div v-if="column['key'] == 'badge'" class="h-4 w-4 rounded-full bg-dwgreen" :class="{'bg-bwgreen': column['value'], 'bg-dwred': !column['value']}"></div>
                  <a :href="column['value']" v-if="column['key'] == 'link' && column['icon']" :class="'text-'+ column['color'] +'-500 hover:text-'+ column['color'] + '-900 text-right'"><i :class="'fa-solid fa-' + column['icon']"></i></a>
                  <a :href="column['value']" v-if="column['key'] == 'link' && column['text'] && !column['icon']" class="text-dwgreen-600 hover:text-dwgreen-900 text-right">{{column['text']}}</a>
                  <a :href="column['value']" v-if="column['key'] == 'link'&& !column['text'] && !column['icon']" class="text-dwgreen-600 hover:text-dwgreen-900 text-right">Bekijken</a>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div class="flex-1 flex justify-between sm:hidden">
              <button @click="previousPage" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" :class="{'bg-gray-300 hover:bg-gray-300': total_pages == 1 || page == 1}"> Vorige </button>
              <span class="text-gray-700 mt-2">Pagina {{page}}</span>
              <button @click="nextPage" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" :class="{'bg-gray-300 hover:bg-gray-300': total_pages == 1 || page == 1}"> Volgende </button>
            </div>
            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p class="text-sm text-gray-700" v-if="filtered_data.length > 0">
                  <span class="font-medium">{{ ((page -1) * per_page) == 0 ? 1 : (page -1) * per_page }}</span>
                  tot
                  <span class="font-medium">{{ ( page * per_page ) > filtered_data.length ? filtered_data.length : page * per_page}}</span>
                  van de 
                  <span class="font-medium">{{ filtered_data.length }}</span>
                  resultaten
                </p>
                <p class="text-sm text-gray-700" v-if="filtered_data.length == 0">Geen resultaten</p>
              </div>
              <div>
                <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <button @click="previousPage" v-if="total_pages" :class="{'bg-gray-300 hover:bg-gray-300': total_pages == 1 || page == 1}" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <span class="sr-only">Vorige</span>
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                  </button>

                  <button v-if="total_pages <= 10" @click="toPage(index)" v-for="index in total_pages" :key="'d' + index" aria-current="page" :class="{'z-10 bg-dwgreen-50 border-dwgreen-500 text-dwgreen-600': index == page, 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50': index != page}" class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"> {{ total_pages > 10 ? index + page : index }} </button>

                  <button v-if="total_pages > 10 && (total_pages > page + 5)" @click="toPage((index-1) + page)" v-for="index in 3" :key="'c' + index" aria-current="page" :class="{'z-10 bg-dwgreen-50 border-dwgreen-500 text-dwgreen-600': (index-1) + page == page, 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50': (index-1) + page != page}" class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"> {{ (index-1) + page }} </button>

                  <button v-if="total_pages > 10 && total_pages > page + 5" aria-current="page" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> ... </button>

                  <button v-if="total_pages > 10 && (total_pages > page + 5)" @click="toPage(last_page(index, 3))" v-for="index in 3" :key="'b' + index" aria-current="page" :class="{'z-10 bg-dwgreen-50 border-dwgreen-500 text-dwgreen-600': last_page(index, 3) == page, 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50': index != page}" class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"> {{ last_page(index, 3) }} </button>

                  <button v-if="total_pages > 10 && !(total_pages > page + 5)" @click="toPage(last_page(index, 6))" v-for="index in 6" :key="'a' + index" aria-current="page" :class="{'z-10 bg-dwgreen-50 border-dwgreen-500 text-dwgreen-600': last_page(index, 6) == page, 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50': index != page}" class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"> {{ last_page(index, 6) }} </button>

                  <button @click="nextPage" v-if="total_pages" :class="{'bg-gray-300 hover:bg-gray-300': total_pages == 1 || page == total_pages}" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <span class="sr-only">Volgende</span>
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Table",
    data() {
      return {
        sort: null,
        order: "asc",
        filter: "",
        page: 1,
      }
    },
    props: {
      headers: {
        type: Array,
        default: [],
        required: true
      },
      data: {
        type: Array,
        default: [], 
        required: true
      },
      per_page: {
        type: Number,
        default: 15,
        required: false
      },
      with_search: {
        type: Boolean,
        default: true,
        required: false
      }
    },
    watch: {
      // whenever question changes, this function will run
      filter() {
        this.page = 1;
      }
    },
    computed: {
      total_pages() {
        return Math.ceil(this.filtered_data.length / this.per_page)
      },
      filtered_data() {
        var filteredData = this.data
        if(this.filter != "") {
          filteredData = filteredData.filter(data =>
            (data[0] && data[0]["key"] != "link" && data[0]["value"] != null && data[0]["value"].toString().toLowerCase().includes(this.filter.toLowerCase())) ||
            (data[1] && data[1]["key"] != "link" && data[1]["value"] != null && data[1]["value"].toString().toLowerCase().includes(this.filter.toLowerCase())) ||
            (data[2] && data[2]["key"] != "link" && data[2]["value"] != null && data[2]["value"].toString().toLowerCase().includes(this.filter.toLowerCase())) ||
            (data[3] && data[3]["key"] != "link" && data[3]["value"] != null && data[3]["value"].toString().toLowerCase().includes(this.filter.toLowerCase())) ||
            (data[4] && data[4]["key"] != "link" && data[4]["value"] != null && data[4]["value"].toString().toLowerCase().includes(this.filter.toLowerCase())) ||
            (data[5] && data[5]["key"] != "link" && data[5]["value"] != null && data[5]["value"].toString().toLowerCase().includes(this.filter.toLowerCase())) ||
            (data[6] && data[6]["key"] != "link" && data[6]["value"] != null && data[6]["value"].toString().toLowerCase().includes(this.filter.toLowerCase())) ||
            (data[7] && data[7]["key"] != "link" && data[7]["value"] != null && data[7]["value"].toString().toLowerCase().includes(this.filter.toLowerCase())) ||
            (data[8] && data[8]["key"] != "link" && data[8]["value"] != null && data[8]["value"].toString().toLowerCase().includes(this.filter.toLowerCase())) ||
            (data[9] && data[9]["key"] != "link" && data[9]["value"] != null && data[9]["value"].toString().toLowerCase().includes(this.filter.toLowerCase())) ||
            (data[10] && data[10]["key"] != "link" && data[10]["value"] != null && data[10]["value"].toString().toLowerCase().includes(this.filter.toLowerCase()))
          );
        }
        return filteredData
      },
      sorted_data() {
        var sortedData = this.filtered_data;
        if(this.sort) {

          const index = sortedData[0].findIndex(values => {
            return values["key"] === this.sort;
          });

          sortedData = sortedData.sort((a,b) => {
            if (Number.isInteger(a[index]["value"])) {
              var fa = a[index]["value"], fb = b[index]["value"];
            } else {
              var fa = a[index]["value"].toLowerCase(), fb = b[index]["value"].toLowerCase();
            }
            
            if (fa < fb) {
              return -1
            }
            if (fa > fb) {
              return 1
            }
            return 0
          })
        }
        if(this.order == "asc") {
          return sortedData.slice((this.page -1) * this.per_page, this.page * this.per_page);
        } else if(this.order == "desc") {
          return this.reverseArr(sortedData).slice((this.page -1) * this.per_page, this.page * this.per_page);
        } else {
          return sortedData.slice((this.page -1) * this.per_page, this.page * this.per_page);
        }
      }
    },
    methods: {
      mobileRow(row) {
        var test = row.slice()
        return test.splice(2)
      },
      nextPage() {
        if(this.total_pages > 1 && this.total_pages != this.page) {
          this.page = this.page + 1 
        }
      },
      previousPage() {
        if(this.total_pages > 1 && this.page != 1) {
          this.page = this.page - 1
        }
      },
      toPage(page) {
        this.page = page;
      }, 
      clearFilter() {
        this.filter = "";
      },
      setSort(header) {
        if(this.sort == header["key"].toLowerCase()) {
          if(this.order == "asc") {
            this.order = "desc"
          } else {
            this.order = "asc"
          }
        } else {
          this.sort = header["key"].toLowerCase();
          this.order = "asc"
        }
      },
      reverseArr(input) {
        var ret = new Array;
        for(var i = input.length-1; i >= 0; i--) {
          ret.push(input[i]);
        }
        return ret;
      },
      isActive(header) {
        return this.sort == header["key"].toLowerCase();;
      },
      last_page(index, total) {
        return this.total_pages - (total - index)
      }
    }
  }
</script>