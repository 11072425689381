<template>
  <div>
    <div class="mt-5 md:mt-0">
      <div class="shadow sm:rounded-md">
        <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
          <div class="flex justify-between">
            <span>
              <label for="company-website" class="block text-sm font-medium text-gray-700"> Naam klant </label>
              <p class="mt-1">
                {{ customer_name }}
              </p>
            </span>
            <span>
              <label class="block text-sm font-medium text-gray-700"> Categorie </label>
              <div class="mt-1">
                <p class="mt-1 text-right break-words max-w-xs">{{ category }}</p>
              </div>
            </span>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700"> Issue </label>
            <div class="mt-1">
              <p class="mt-1">{{ issue }}</p>
            </div>
          </div>


          <div>
            <label class="block text-sm font-medium text-gray-700"> Contact gegevens </label>
            <div class="mt-1">
              <p class="mt-1">{{ contact_method }}: {{ contact_value }}</p>
            </div>
          </div>

          <div class="flex justify-between">
            <div>
              <label class="block text-sm font-medium text-gray-700"> Mee bezig </label>
              <div class="mt-1 flex items-center">
                <span class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                  <svg v-if="!user.profile_picture" class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  <img v-if="user.profile_picture" :src="user.profile_picture" class="h-full w-full text-gray-300" />
                </span>
                <p class="ml-3">{{ user.name }}</p>
                <div class="relative inline-block text-left">
                <button class="mt-2">
                  <svg @click="show_users = !show_users" @blur="show_users = false" class="-mr-1 ml-2 h-5 w-5 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
                <div @mousedown.prevent v-if="show_users" class="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                  <div class="py-1 hover:bg-gray-200" role="none" v-for="(user_list, index) in users" :key="index" @click="setUser(user_list)">
                    <div class="text-gray-700 block px-4 py-2 text-sm cursor-pointer">{{ user_list.name }}</div>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-700"> Opgelost: </label>
              <i :class="`fa-solid fa-${solved ? 'check text-dwgreen-300' : 'clock text-gray-300'} text-2xl mt-1 float-right`"></i>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <span class="float-left mr-5 lg:mr-40">
            <a :href="back_url" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300">Terug</a>
            <button @click="deleteTicket()" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dwred-600 hover:bg-dwred-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dwred-500">Verwijderen</button>
          </span>
          <span>
            <button v-if="!solved" @click="markSolved(true)" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dwgreen-600 hover:bg-dwgreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dwgreen-500">Afronden</button>
            <button v-else @click="markSolved(false)" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200">Afronden ongedaan maken</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
  export default {
    name: 'ShowSupportTicket',
    data() {
      return {
        show_users: false,
        solved: false,
      }
    },
    mounted() {
      this.solved = this.completed;
    },
    methods: {
      async setUser(newUser) {
        await axios.post("/api/support_ticket/set_user.json", {user_id: newUser.id, ticket_id: this.ticket_id}).then(response => {
          if(response.status == 200) {
            this.user = newUser;
            this.show_users = false;
          }
        });
      },
      async markSolved(mark_solved) {
        await axios.post("/api/support_ticket/solved.json", {solved: mark_solved, ticket_id: this.ticket_id}).then(response => {
          console.log(response);
          if(response.status == 200) {
            this.solved = !this.solved;
          }
        });
      },
      async deleteTicket() {
        if(confirm("Weet je zeker dat je deze ticket wilt verwijderen?")){
            await axios.post("/api/support_ticket/delete_ticket", {id: this.ticket_id}).then(response => {
            console.log(response);
            if(response.status == 200) {
              window.location.href = this.back_url
            }
        });
        }
      }
    },
    props: {
      customer_name: {
        type: String,
        default: '',
        required: true,
      },
      issue: {
        type: String,
        default: '',
        required: true,
      },
      contact_method: {
        type: String,
        default: '',
        required: true,
      },
      contact_value: {
        type: String,
        default: '',
        required: true,
      },
      category: {
        type: String,
        default: '',
        required: true,
      },
      user: {
        type: Object,
        default: '',
        required: true,
      },
      users: {
        type: Array,
        default: [],
        required: true,
      },
      completed: {
        default: false,
        required: false,
      },
      ticket_id: {
        type: Number,
        default: false,
        required: true,
      },
      back_url: {
        type: String,
        default: '',
        required: true,
      },
    }
  }
</script>