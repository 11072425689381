<template>
  <div>
      <input type="hidden" name="authenticity_token" value="023Wb9yBKc5z-ZOGFH9wXhKhngHwANzlwJCSuwNgP8AEIKMzr42j9gNYK-ywNB6ta4hpmwCeKEs0l7qUBb43CQ" autocomplete="off"> 
      <input type="hidden" name="work_session[user_id]" v-model="work_session_user_id" > 
        <div class="w-full">
          <label for="work_session_work_date" class="text-sm">Datum</label> 
          <input type="date" @change="timeConvert()" v-model="work_date" id="work_session_work_date" name="work_session[work_date]" class="block w-full shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 text-sm max-w-32" required>
        </div> 
        <div class="flex justify-between">
          <div>
            <label for="work_session_begin_time" class="text-sm">Begin tijd</label> 
            <input id="begin_time" @change="timeConvert()" v-model="begin_time" type="time" name="work_session[begin_time]" class="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 text-sm w-full" required>
          </div> 
          <div class="pt-[40px]">
            -
          </div>
          <div>
            <label for="work_session_end_time" class="text-sm">Eind tijd</label> 
            <input id="end_time" @change="timeConvert()" v-model="end_time" type="time" name="work_session[end_time]" class="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 text-sm w-full" required>
          </div> 
        </div>
          <div>
            <label for="work_session_hours_worked" class="text-sm">Gewerkte uren</label> 
            <input id="total" type="number" step=".1" @change="timeConvert()" name="work_session[hours_worked]" v-model="total" class="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 text-sm w-full" required readonly>
          </div>
        <div class="mt-4">
          <label for="work_session_hours_worked" class=" mt-3 text-sm">Activiteit</label>
          <select id="" type="" name="work_session[work_assignment_id]" class="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2  text-sm w-full" required>
            <option readonly value="">Kies je activiteit</option>
            <option v-for="item in work_assignments" :key="item.id" :value="item.id">{{item.name}}</option>
          </select>
        </div>
        <p>{{message}}</p>
        <div class="py-3">
          <input type="submit" name="commit" value="Verstuur uren" data-disable-with="Verstuur uren" class="inline-flex items-center justify-center rounded-md border border-transparent bg-dwgreen-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-dwgreen-700 focus:outline-none focus:ring-2 focus:ring-dwgreen-500 focus:ring-offset-2 sm:w-auto" >
        </div>
      </div>
  </div>
</template>

<script>
  export default {
    name: "TotalWorkedHours",

    props: {
      work_session_user_id: {
        type: Number,
      },
      work_assignments: {
        type: Object
      }
    },

    data() {
      return {
        text: 'Test text',
        work_date: null,
        begin_time: null,
        end_time: null,
        total: null,
        message: null,
      }
    },
    methods: {
      changeText(){
        this.text = "nieuwe text";
      },
      timeConvert() {
        if( this.work_date && this.end_time && this.begin_time ) {
          var num =  new Date(this.work_date + "T" + this.end_time).getTime() - new Date(this.work_date + "T" + this.begin_time).getTime();
          var hours = (num / 1000 / 60 / 60).toFixed(1);

          var rhours = Math.floor(hours);
          var minutes = (hours - rhours) * 60;
          var rminutes = Math.round(minutes);
          
          this.total = hours
          this.message = "Goed gedaan! Je hebt " + rhours + " uur en " + rminutes + " minuten gewerkt!";
        }
      },
      selectAssignment() {

      },
    },
  }
</script>