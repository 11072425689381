
import Vue from 'vue/dist/vue.esm';

// SIDEBAR
import SidebarFolder from '../components/sidebar/SidebarFolder.vue'
Vue.component('sidebarfolder', SidebarFolder)

// FORM CONTACT METHOD
import PhoneOrEmailContact from '../components/forms/PhoneOrEmailContact.vue'
Vue.component('phoneoremailcontact', PhoneOrEmailContact)

// FORM SELECT WITH ADD NEW
import SelectSupportCategory from '../components/forms/SelectSupportCategory.vue'
Vue.component('selectsupportcategory', SelectSupportCategory)

// SUPPORT CATEGORY ADD NEW MODAL FROM TICKETS
import SupportCategoryModal from '../components/support/SupportCategoryModal.vue'
Vue.component('supportcategorymodal', SupportCategoryModal)

// SHOW SUPPORT TICKETS PAGE
import ShowSupportTicket from '../components/support/ShowSupportTicket.vue'
Vue.component('showsupportticket', ShowSupportTicket)

// DEFAULT
import DefaultTable from '../components/default/DefaultTable.vue'
Vue.component('defaulttable', DefaultTable)

// SUPPORT TICKETS
import SupportTicket from '../components/support/SupportTicket.vue'
Vue.component('supportticket', SupportTicket)

// WORKED HOURS STUDENTS
import TotalWorkedHours from '../components/forms/TotalWorkedHours.vue'
Vue.component('totalworkedhours', TotalWorkedHours)