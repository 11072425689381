<template>
  <div class="col-span-6 sm:col-span-3">
    <span v-for="method in methods" v-if="method.id == selected">
      <label for="support_ticket_support_method_value" class='block text-sm font-medium text-gray-700'>{{method.name}} klant</label>
      <input type="text" id="support_ticket_support_method_value" name="support_ticket[support_method_value]" v-model="support_method" class="mt-1 focus:ring-dwblue-500 focus:border-dwblue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
    </span>

    <input type="text" v-model="selected" id="support_ticket_support_method_id" name="support_ticket[support_method_id]" class="hidden">

    <span v-if="!value">
      <label class='block text-sm font-medium text-gray-700'>Issue gemeld vanuit:</label>
      <div class="block lg:flex mt-2">
        <div class="flex mr-6 mb-2" v-for="method in methods">
          <input :id="method.name" @click="selected = method.id" type="radio" value="" name="inline-radio-group" class="w-6 h-6 border-gray-300 focus:bg-dwred text-dwred checked:bg-dwred">
          <label :for="method.name" class="ml-2 text-sm font-medium">{{method.name}}</label>
        </div>
      </div>
    </span>
  </div>
</template>
<script>
  export default {
    name: "PhoneOrEmailContact",
    data() {
      return {
        selected: null,
        support_method: ""
      }
    },
    props: {
      methods: {
        type: Array,
        default: [],
        required: true
      },
      value: {
        type: Number,
        default: null,
        required: false
      },
      support_method_value: {
        type: String,
        default: "",
        required: false
      }
    },
    created() {
      this.selected = this.value;
      // if(this.support_method_value.length > 0) {
      //   this.support_method == this.support_method_value
      // }
    }
  }
</script>