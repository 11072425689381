<template>
  <span :class="`w-full ${cols}`">
    <!-- <label v-if="label" class="block text-sm font-medium text-gray-700 w-full">{{ label }}</label>
    <select :name="name" :id="id" class="bg-white py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 pr-16 mt-2 w-full">
      <option v-for="(option, index) in options" :key="index" :value="parseInt(option.id)">{{ option.name }}</option>
    </select> -->
    <label id="listbox-label" class="block text-sm font-medium text-gray-700">{{ label }}</label>
    <div class="mt-1 relative">
      <button type="button" @blur="dropdown_visible = false" @click="dropdown_visible = !dropdown_visible" class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-dwblue-500 focus:border-dwblue-500 sm:text-sm cursor-pointer" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
        <span class="block truncate">{{ selected_option.name }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </span>
      </button>

      
      <ul @mousedown.prevent :class="`absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm cursor-pointer ${dropdown_visible ? '' : 'hidden'}`" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
        <li v-for="(option, index) in options" :key="index" @click="setOption(option)" class="text-gray-900 select-none relative py-2 pl-3 pr-9" id="listbox-option-0" role="option">
          <span class="font-normal block truncate"> {{ option.name }} </span>
          <span v-if="option.id == selected_option.id" class="text-dwblue-600 absolute inset-y-0 right-0 flex items-center pr-4">
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
          </span>
        </li>
        <button @click="show_add_new_option = true" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dwgreen-600 hover:bg-dwgreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dwgreen-500 mr-1 mb-1 float-right">
          Voeg nieuwe toe
          <svg class="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </button>
      </ul>
    </div>
    <input type="number" class="hidden" :name="name" :id="id" :value="selected_option.id">
  </span>
</template>
<script>
  import axios from "axios"
  export default {
    name: "SelectSupportCategory",
    props: {
      cols: {
        type: String,
      },
      label: {
        type: String
      },
      name: {
        type: String,
      },
      id: {
        type: String,
      },
      options: {
        type: Array,
        required: true,
      },
      selectedOption: {
        type: Array,
        required: false,
      },
      users: {
        type: Array,
        required: true,
      },
      product: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        selected_option: null,
        dropdown_visible: false,
        show_add_new_option: false,
        category_name: null,
        description: null,
        user_id: null,
        support_product_id: this.product['id'],
      }
    },
    created() {
      if(this.selectedOption) {
        this.selected_option = this.selectedOption;
      }
      else {
        this.selected_option = this.options[0];
      }
    },
    methods: {
      setOption(option) {
        this.selected_option = option;
        this.dropdown_visible = false;
      },
      async add_new_option() {
        await axios.post("/api/support_categories/create.json", {name: this.category_name, description: this.description, user_id: this.user_id, support_product_id: this.support_product_id}).then(response => {
          // console.log(response);
        });

        this.options.push(this.support_category.slice());
        this.selected_option = this.support_category;
        this.dropdown_visible = false;
        this.show_add_new_option = false;
        // this.support_category.name = null;
        // this.support_category.description = null;
        // this.support_category.user_id = null;
        // this.support_category.support_product_id = this.product['id'];
      },
    }
  }
</script>