<template>
  <div>
    <div v-if="folder.items">
        <span>
          <span v-if="folder.path"  class="flex justify-between text-gray-300 hover:bg-gray-700 hover:text-white group items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer align-middle" :class="current_page ? 'bg-gray-900' : ''">
            <a v-if="folder.path" :href="folder.path" class="grow">
              <span>
                <i class="fa-solid text-gray-300 mr-2" :class="folder.icon ? 'fa-' + folder.icon : 'fa-folder' "></i>
                {{folder.text}}
              </span>
            </a> 
            <span @click="extend_folder = !extend_folder" class="mb-3">
              <i :class="`fa-solid fa-chevron-${extend_folder ? 'up' : 'down'} text-gray-300  absolute right-4`"></i>
            </span>
          </span>
          <span v-else @click="extend_folder = !extend_folder"  class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer" :class="current_page ? 'bg-gray-900' : ''">
              <i class="fa-solid text-gray-300 mr-3" :class="folder.icon ? 'fa-' + folder.icon : 'fa-folder'"></i>
              {{folder.text}}
              <i :class="`fa-solid fa-chevron-${extend_folder ? 'up' : 'down'} text-gray-300 absolute right-4`"></i>
          </span>
        </span>
        <span v-for="(item, index) in folder.items" :key="index" >
          <a v-if="!item.policy || user_policies.includes(item.policy)" :href="item.path" class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md ml-5 cursor-pointer" :style="extend_folder ? 'display: block' : 'display: none'" :class="current_item(item) ? 'bg-gray-900' : ''">
            <i :class="`fa-solid fa-${item.icon} text-gray-300 mr-3`" ></i>
            {{item.text}}
          </a>
        </span>
    </div>
    <div v-else>
      <a :href="folder.path" class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer" :class="current_page ? 'bg-gray-900' : ''">
        <i :class="`fa-solid fa-${folder.icon} text-gray-300 mr-3`"></i>
        {{folder.text}}
      </a>
    </div>
  </div>
</template>
<script>
  export default {
    name: "SidebarFolder",
    props: {
      folder: {
        type: Object,
        default: () => [],
        required: true
      },
      active: {
        type: String
      },
      user_policies: {
        type: Array,
        default: [],
        required: true
      }
    },
    data() {
      return {
        extend_folder: false,
        current_page: false,
        path: window.location.href.split('/').slice(3, window.location.href.split('/').length)[0]
      }
    },
    created() {
      if(this.folder.items != undefined && this.folder.items.length > 0) {
        if(this.folder.items.filter(item => this.active == item.text).length > 0) {
          this.extend_folder = true;
          this.current_page = true;
        } else if(this.folder.text == this.active) {
          this.extend_folder = true;
          this.current_page = true;
        }
      } else {
        if(this.folder.text == this.active) {
          this.current_page = true;
        }
      }
    },
    methods: {
      current_item(item) {
        if(this.current_page && this.active == item.text) {
          return true
        }
      }
    }
  }
</script>