<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
        <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full sm:p-6">
          <div>
            <div class="mt-3 sm:mt-5">
              <h3 class="text-lg leading-6 font-medium text-gray-900 text-center" id="modal-title">Voeg een nieuwe toe</h3>
              <div class="mt-2">
                <div class="text-sm text-gray-500 grid grid-cols-4 gap-4 mt-5">

                  <div class="col-span-4">
                    <span>
                      <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-2">Naam</label>
                      <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full shadow-sm focus:ring-dwblue-500 focus:border-dwblue-500 sm:text-sm border-gray-300 rounded-md" v-model="category_name">
                    </span>
                  </div>

                  <div class="col-span-4">
                    <span>
                      <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-2">Beschrijving</label>
                      <textarea id="message" rows="3" class="block p-2.5 w-full text-sm text-gray-700 rounded-lg border border-gray-300 focus:ring-dwblue-500 focus:border-dwblue-500" placeholder="Een korte beschrijving..." v-model="description"></textarea>
                    </span>
                  </div>

                  <div class="col-span-2">
                    <span>
                      <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-2">Hoofd verantwoordelijke</label>
                      <select id="countries" class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-dwblue-500 focus:border-dwblue-500 block w-full p-2.5" v-model="user_id">
                        <option :value="null" selected>Niemand</option>
                        <option v-for="(user, index) in users" :key="index" :value="user.id">{{ user.name }}</option>
                      </select>
                    </span>
                  </div>

                  <div class="col-span-2">
                    <span>
                      <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-2">Support product</label>
                      <select id="countries" class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-dwblue-500 focus:border-dwblue-500 block w-full p-2.5" v-model="support_product_id">
                        <option v-for="(prod, index) in [product]" :key="index" :value="prod['id']" :selected="index == 0">{{ prod['name'] }}</option>
                      </select>
                    </span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button @mousedown="add_new_option()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dwgreen-600 text-base font-medium text-white hover:bg-dwgreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dwgreen-500 sm:col-start-2 sm:text-sm">Opslaan</button>
            <button @click="show_add_new_option = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm">Terug</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SupportCategoryModal'
  }
</script>