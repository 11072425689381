<template>
  <div class="h-full" @keyup.esc="switchStep(current_step - 1)">
    <div class="container mx-auto">
      <!-- begin Header -->
      <div class="pb-5 border-b border-gray-200 mb-8">
        <div class="sm:flex sm:justify-between sm:items-baseline">
          <div class="sm:w-0 sm:flex-1">
            <h1 id="message-heading" class="text-lg font-medium text-gray-900">Nieuwe support ticket</h1>
            <p class="mt-1 text-sm text-gray-500 truncate">van {{support_product.name}}</p>
          </div>

          <div class="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
            <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-dwgreen-400 text-white"> Nieuw </span>
          </div>
        </div>
      </div>
      <!-- end Header -->

      <!-- begin Progressbar -->
      <div class="mb-5">
        <nav aria-label="Progress">
          <ol role="list" class="space-y-4 md:flex md:space-y-0 md:space-x-8">
            <li class="md:flex-1">
              <div @click="switchStep(1)" class="pl-4 py-2 flex flex-col border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4 cursor-pointer" aria-current="step"
              :class="{'border-dworange-600': current_step == 1, 'border-dworange-600 hover:border-dworange-800': current_step == 3 || current_step == 2}">
                <span class="text-xs font-semibold tracking-wide uppercase"
                :class="{'text-dworange-600': current_step == 1, 'text-dworange-600 group-hover:text-dworange-800': current_step == 3 || current_step == 2}">Stap 1</span>
                <span class="text-sm font-medium">Probleem identificeren</span>
              </div>
            </li>

            <li class="md:flex-1">
              <div @click="switchStep(2)" class="pl-4 py-2 flex flex-col border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4 cursor-pointer" aria-current="step"
              :class="{'border-gray-200 hover:border-gray-300': current_step == 1, 'border-dworange-600': current_step == 2, 'border-dworange-600 hover:border-dworange-800': current_step == 3}">
                <span class="text-xs font-semibold tracking-wide uppercase"
                :class="{'text-gray-500 group-hover:text-gray-700': current_step == 1, 'text-dworange-600': current_step == 2, 'text-dworange-600 group-hover:text-dworange-800': current_step == 3}">Stap 2</span>
                <span class="text-sm font-medium">Gegevens gebruiker</span>
              </div>
            </li>

            <li class="md:flex-1">
              <div @click="switchStep(3)" class="pl-4 py-2 flex flex-col border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4 cursor-pointer" aria-current="step"
              :class="{'border-gray-200 hover:border-gray-300': current_step == 1 || current_step == 2, 'border-dworange-600': current_step == 3}">
                <span class="text-xs font-semibold tracking-wide uppercase"
                :class="{'text-gray-500 group-hover:text-gray-700': current_step == 1|| current_step == 2, 'text-dworange-600': current_step == 3}">Stap 3</span>
                <span class="text-sm font-medium">Afronden</span>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <!-- end Progressbar -->

      <div class="h-96">
        <!-- begin Step 1 -->
        <div v-if="current_step == 1" class="grid md:grid-cols-2 gap-6">
          <div class="col-span-1">
            <label for="comment" class="block text-sm font-medium text-gray-700">Het probleem</label>
            <div class="mt-1">
              <textarea ref="problem" rows="8" v-model="support_ticket.issue" name="comment" id="comment" class="shadow-sm focus:ring-dwgreen-500 focus:border-dwgreen-500 block sm:text-sm border-gray-300 rounded-md w-full w-md-auto"></textarea>
            </div>
          </div>
          <div class="col-span-1">
            <label class="block text-sm font-medium text-gray-700">Categorie</label>
            <multiselect v-model="support_ticket.support_category" :options="display_categories" placeholder="Selecteer een categorie" label="name" track-by="id" />
            <small class="cursor-pointer" @click="show_add_new_option = true"><i class="fas fa-plus"></i> Nieuwe categorie</small>
          </div>
        </div>
        <!-- end Step 1 -->

        <!-- begin Step 2 -->
        <div v-if="current_step == 2" class="grid grid-cols-2 gap-6">
          <div class="col-span-2">
            <label for="name" class="block text-sm font-medium text-gray-700">Klant</label>
            <div class="mt-1">
              <input @keydown.enter="$event.keyCode === 13 ? $event.preventDefault() : false" ref="name" type="text" name="name" id="name" v-model="support_ticket.name" class="shadow-sm focus:ring-dwgreen-500 focus:border-dwgreen-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Naam klant">
            </div>
          </div>
          <div class="col-span-1">
            <label for="support_method" class="block text-sm font-medium text-gray-700">Methode van contact</label>
            <select id="support_method" name="support_method" v-model="support_ticket.support_method" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-dwgreen-500 focus:border-dwgreen-500 sm:text-sm rounded-md">\
              <option disabled value="">Kies een methode</option>
              <option v-for="(support_method, index) in support_methods" :key="index" :value="support_method" >{{support_method.name}}</option>
            </select>
          </div>
          <div class="col-span-1" v-if="support_ticket.support_method.name">
            <label for="support_method_value" class="block text-sm font-medium text-gray-700">{{support_ticket.support_method.name}}</label>
            <div class="mt-1">
              <input @keydown.enter="$event.keyCode === 13 ? $event.preventDefault() : false" type="text" name="support_method_value" id="support_method_value" v-model="support_ticket.support_method_value" class="shadow-sm focus:ring-dwgreen-500 focus:border-dwgreen-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Contactgegevens">
            </div>
          </div>
        </div>
        <!-- end Step 2 -->

        <!-- begin Step 3 -->
        <div v-if="current_step == 3" class="grid md:grid-cols-2 gap-6"> 
          <div class="col-span-1">
            <label for="user" class="block text-sm font-medium text-gray-700">Gekoppelde medewerker</label>
            <select ref="user" id="user" name="user" v-model="support_ticket.user" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-dwgreen-500 focus:border-dwgreen-500 sm:text-sm rounded-md">
              <option v-for="(user, index) in users" :key="index" :value="user" >{{user.name}}</option>
            </select>
          </div>
          <div class="col-span-1 my-auto float-right">
            <input @keydown.enter="$event.keyCode === 13 ? $event.preventDefault() : false" @click="setSolved()" @keyup.enter="setSolved()" v-model="solved_at_checkbox" id="link-checkbox" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2">
            <label for="link-checkbox" class="ml-2 text-sm font-medium text-gray-900">Opgelost?</label>
          </div>
        </div>
        <!-- end Step 3 -->
      </div>
    </div>

    <!-- begin Variables -->
    <div class="hidden">
      <input type="hidden" name="support_ticket[user_id]" :value="support_ticket.user.id" />
      <input type="hidden" name="support_ticket[support_category_id]" :value="support_ticket.support_category.id" />
      <input type="hidden" name="support_ticket[issue]" :value="support_ticket.issue" />
      <input type="hidden" name="support_ticket[name]" :value="support_ticket.name" />
      <input type="hidden" name="support_ticket[support_product_id]" :value="support_ticket.support_product.id" />
      <input type="hidden" name="support_ticket[support_method_value]" :value="support_ticket.support_method_value" />
      <input type="hidden" name="support_ticket[support_method_id]" :value="support_ticket.support_method.id" />
      <input type="hidden" name="support_ticket[solved_at]" :value="support_ticket.solved_at ? support_ticket.solved_at : null" />
    </div>
    <!-- end Variables -->
    <div class="flex justify-between flex-row-reverse">
      <button @click="switchStep(current_step + 1)" v-if="current_step <= 2" type="button" class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dwgreen-600 text-base font-medium text-white hover:bg-dwgreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dwgreen-500 sm:col-start-2 sm:text-sm">Volgende</button>
      <button v-if="current_step == 3 && !switching" type="submit" :class="`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-dwgreen-600 hover:bg-dwgreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dwgreen-500 ${(checkButtonDisabled() == true) ? 'opacity-50 cursor-not-allowed' : '' }`" :disabled="checkButtonDisabled()">
        <i class="fas fa-check fa-lg mr-2 -ml-1"/>
        Opslaan
      </button>
      <button @click="switchStep(current_step - 1)" v-if="current_step > 1" type="button" class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dwgreen-600 text-base font-medium text-white hover:bg-dwgreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dwgreen-500 sm:col-start-2 sm:text-sm">Vorige</button>
    </div>

    <!-- <SupportCategoryModal /> -->
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="show_add_new_option">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full sm:p-6">
            <div>
              <div class="mt-3 sm:mt-5">
                <h3 class="text-lg leading-6 font-medium text-gray-900 text-center" id="modal-title">Voeg een nieuwe toe</h3>
                <div class="mt-2">
                  <div class="text-sm text-gray-500 grid grid-cols-4 gap-4 mt-5">

                    <div class="col-span-4">
                      <span>
                        <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-2">Naam</label>
                        <input @keydown.enter="$event.keyCode === 13 ? $event.preventDefault() : false" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full shadow-sm focus:ring-dwblue-500 focus:border-dwblue-500 sm:text-sm border-gray-300 rounded-md" v-model="new_category.category_name">
                      </span>
                    </div>

                    <div class="col-span-4">
                      <span>
                        <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-2">Beschrijving</label>
                        <textarea id="message" rows="3" class="block p-2.5 w-full text-sm text-gray-700 rounded-lg border border-gray-300 focus:ring-dwblue-500 focus:border-dwblue-500" placeholder="Een korte beschrijving..." v-model="new_category.description"></textarea>
                      </span>
                    </div>

                    <div class="col-span-2">
                      <span>
                        <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-2">Hoofd verantwoordelijke</label>
                        <select id="countries" class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-dwblue-500 focus:border-dwblue-500 block w-full p-2.5" v-model="new_category.user_id">
                          <option :value="null" selected>Niemand</option>
                          <option v-for="(user, index) in users" :key="index" :value="new_category.user_id">{{ user.name }}</option>
                        </select>
                      </span>
                    </div>

                    <div class="col-span-2">
                      <span>
                        <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mb-2">Support product</label>
                        <select id="countries" class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-dwblue-500 focus:border-dwblue-500 block w-full p-2.5" v-model="new_category.support_product_id">
                          <option :value="null" selected>Geen product</option>
                          <option v-for="(prod, index) in [support_product]" :key="index" :value="prod.id">{{ prod.name }}</option>
                        </select>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button @mousedown="add_new_option()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dwgreen-600 text-base font-medium text-white hover:bg-dwgreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dwgreen-500 sm:col-start-2 sm:text-sm">Opslaan</button>
              <button @click="show_add_new_option = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm">Sluiten</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import axios from 'axios'
  // import SupportCategoryModal from './SupportCategoryModal'

  export default {
    name: 'SupportTicket',
    components: {
      // SupportCategoryModal
    },
    data() {
      return {
        switching: false,
        current_step: 1,
        support_ticket: {
          support_category: {
            id: null,
            name: null,
          },
          issue: null,
          name: null,
          user: {
            id: null,
            name: null,
          },
          support_product: {
            id: null,
            name: null,
          },
          support_method_value: null,
          support_method: {
            id: null,
            name: null,
          },
          solved_at: null,
        },
        display_categories: null,
        solved_at_checkbox: false,


        show_add_new_option: false,

        new_category: {
          category_name: null,
          description: null,
          user_id: null,
          support_product_id: null
        }
      }
    },
    created() {
      this.support_ticket.support_product = this.support_product;
      this.support_ticket.user = this.current_user;
      this.display_categories = this.support_categories;
    },
    mounted() {
      this.$refs.problem.focus();
    },
    methods: {
      switchStep(step) {
        if(step > 0 && step < 4) {
          this.switching = true
          this.current_step = step;
          if(step == 1) {
            setTimeout(() => {  this.switching = false; }, 100);
          } else if (step == 2) {
            setTimeout(() => {  this.switching = false; }, 100);
          } else if (step == 3) {
            setTimeout(() => {  this.switching = false; }, 100);
          }
        }
      },
      setSolved() {
        if(this.support_ticket.solved_at == null) {
          this.support_ticket.solved_at = Date.now();
          this.solved_at_checkbox = true;
          console.log(this.support_ticket.solved_at)
        } else {
          this.support_ticket.solved_at = null
          this.solved_at_checkbox = false;
        }
      },
      async add_new_option() {
        await axios.post("/api/support_categories/create.json", {name: this.new_category.category_name, description: this.new_category.description, user_id: this.new_category.user_id, support_product_id: this.new_category.support_product_id}).then(response => {
          if (response.status == 200) {
            this.show_add_new_option = false;
            this.display_categories.push(response.data.support_category);
            this.support_ticket.support_category = response.data.support_category;
          }
        });
      },
      checkButtonDisabled() {
        if(this.support_ticket.support_category.id != null && this.support_ticket.support_category.name != null && this.support_ticket.issue != null && this.support_ticket.name != null && this.support_ticket.user.id != null && this.support_ticket.user.name != null && this.support_ticket.support_product.id != null && this.support_ticket.support_product.name != null && this.support_ticket.support_method_value != null && this.support_ticket.support_method.id != null && this.support_ticket.support_method.name != null) {
          return false
        } else {
          return true
        }
      }
    },
    props: {
      support_categories: {
        type: Array,
        default: [],
        required: true,
      },
      support_product: {
        type: Object,
        default: null,
        required: true,
      },
      current_user: {
        type: Object,
        default: null,
        required: true,
      },
      users: {
        type: Array,
        default: [],
        required: true
      },
      support_methods: {
        type: Array,
        default: [],
        required: true,
      },
    },
    components: { Multiselect },
  }
</script>